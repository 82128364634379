<template>
  <el-container>
    <main class="page__container">
      <div class="container">
        <h2>Список процессов автоматического резервирования
          <i v-if="loading" class="el-icon-loading" style="margin-left: 10px;"></i>
        </h2>
        <el-table :data="list">
          <el-table-column label="Сервер" prop="server_name_from">
            <template slot-scope="scope">
              <el-tooltip content="Перейти в ЛК Оператора" :open-delay="1000">
                <el-link type="primary" @click="goToLKOperator(scope.row.server_from, token)" class="link">
                  {{ scope.row.server_from.name }}
                </el-link>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Резервный сервер" prop="server_name_to">
            <template slot-scope="scope">
              <el-tooltip content="Перейти в ЛК Оператора" :open-delay="1000">
                <el-link type="primary" @click="goToLKOperator(scope.row.server_to, token)" class="link">
                  {{ scope.row.server_to.name }}
                </el-link>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Статус" prop="status_description">
            <template slot-scope="scope">
              <router-link v-if="scope.row.is_report_accessible === 1" class="link" :to="{ name: 'reserve-transfers:report', params: { id: scope.row.id_reserve_transfer } }">
                {{ scope.row.status_description }}
              </router-link>
              <template v-else>
                {{ scope.row.status_description }}
              </template>
            </template>
          </el-table-column>
          <el-table-column label="Дата/время" prop="start_dt" />
        </el-table>
      </div>
    </main>
  </el-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatAppUrl from "@/core/helpers/formatAppUrl";
import Vue from "vue";
import { VK_OPERATOR_TOKEN_COOKIE_KEY } from "@/core/helpers/const";

export default {
  name: "reserve-transfers-list",
  computed: {
    ...mapState("reserveTransfers", ["list"]),
    ...mapState("auth", ["token"]),
  },
  data() {
    return {
      loading: true
    };
  },
  created () {
    this.loading = true
    this.getList().then(() => this.loading = false)
    this.intervalId = setInterval(() => {
      this.loading = true
      setTimeout(() => this.loading = false, 2000)
      this.getList()
    }, 8000)
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  },
  methods: {
    ...mapActions("reserveTransfers", {
      getList: "GET_LIST"
    }),
    goToLKOperator (item, token) {
      const url = `${formatAppUrl(item.operator_domain)}auth?operator=${item.id_operator}&redirectPath=servers/edit/${item.id}`;
      Vue.$cookies.set(VK_OPERATOR_TOKEN_COOKIE_KEY, token);
      window.open(url, '_blank');
    },
  },
};
</script>

<style>
</style>
